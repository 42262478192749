<template>
  <!-- <my-section title="天游线路检测中心-欢迎光临"> -->
    <div class="culture-cont">
      <dl class="cont cont1">
        <dt>使命</dt>
        <dd>共铸金融服务新体验</dd>
        <dt>愿景</dt>
        <dd>成为客户数智化经营领导者</dd>
      </dl>
      <dl class="cont cont2">
        <dt>价值观</dt>
        <dd>
          <dl>
            <dt>诚信</dt>
            <dd>诚信做人，依法经营，诚实经商，追求多赢。</dd>
            <dt>勤勉</dt>
            <dd>实实在在做事，尽职尽责，高效执行。</dd>
            <dt>进取</dt>
            <dd>勇于承担责任，主动迎接新的任务和挑战。<br>保持好奇心，不断学习，追求卓越。</dd>
            <dt>创新</dt>
            <dd>提倡开放、平等的精神，尊重、鼓励并激发员工的自主性和创新活力。<br>追求天游线路检测中心-欢迎光临和服务创新、天游线路检测中心-欢迎光临创新、管理创新，通过创新产生高附加值的天游线路检测中心-欢迎光临与服务。</dd>
          </dl>
        </dd>
      </dl>
      <!-- <div class="swiper-container culture-swiper" id="js_cultureSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,i) in sliders" :key="i">
            <img :src="item" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div> -->
    </div>
  <!-- </my-section> -->
</template>

<script>
// import { mySection } from '@/components'
// import Swiper from 'swiper';
// import 'swiper/dist/css/swiper.min.css'
export default {
  components: {
    // mySection
  },
  data () {
    return {
      // lowie: false,
      // sliders:[
      //   require('@/assets/images/about/cul-01.jpg'),
      //   require('@/assets/images/about/cul-02.jpg'),
      //   require('@/assets/images/about/cul-03.jpg'),
      //   require('@/assets/images/about/cul-04.jpg'),
      //   require('@/assets/images/about/cul-05.jpg'),
      //   require('@/assets/images/about/cul-06.jpg')
      // ]
    }
  },
  mounted () {
    // let t = this
    // if(!t.$lowie){
    //   const swiper = new Swiper('#js_cultureSwiper', {
    //     pagination: '.swiper-pagination',
    //     paginationClickable: true,
    //     loop: true,
    //     speed: 600,
    //     autoplay: 4000,
    //     observer:true,//修改swiper自己或子元素时，自动初始化swiper
    //     observeParents:true,//修改swiper的父元素时，自动初始化swiper
    //     onTouchEnd: function() {
    //       // touchend的时候轮播图可能还在滑动，立即执行startAutoplay以后不起作用
    //       // 所以加个时间
    //       setTimeout(swiper.startAutoplay,600)
    //     }
    //   })
    // }
    // else{
    //   t.lowie = true
    // }
  },
  methods: {


  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  @import '@/assets/scss/_mixin.scss';
  .culture-cont{
    @extend %c;
    width: 1200px;
    margin: 0 auto;
    padding: 64px 0;
    &.lowie{
      .cont{
        float: none;
        margin: 0 auto 50px;
      }
      .culture-swiper{
        float: none;
        // width: 1200px;
        height: auto;
        margin: 0 auto;
      }
      .swiper-wrapper{
        @extend %c;
      }
      .swiper-slide{
        &+.swiper-slide{
          margin-top: 30px;
        }
      }
    }
    .cont{
      float: left;
      width: 625px;
      &.cont1{
        float: left;
        width: 550px;
        padding-left: 200px;
      }
      &.cont2{
        float: right;
        width: 650px;
      }
      >dt{
        @include lineHeight(40px);
        font-size: 26px;
        color: #28b670;
        margin-bottom: 10px;
      }
      dd{
        font-size: 16px;
        color: #3e3a39;
        line-height: 24px;
        text-align: justify;
      }
      >dd{
        margin-bottom: 32px;
      }
      dl{
        padding-left: 30px;
        dt{
          position: relative;
          @include lineHeight(36px);
          font-size: 20px;
          color: #28b670;
          &:before{
            position: absolute;
            top: 50%; left: -28px;
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: #28b670;
            border-radius: 50%;
            margin-top: -4px;
          }
        }
        dd{
          margin-bottom: 12px;
        }
      }
    }
  }
  .culture-swiper{
    float: right;
    width: 560px;
    height: 400px;
    margin-top: 85px;
    .swiper-slide{
      height: 370px;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
    }
    .swiper-pagination.swiper-pagination-bullets{
      bottom: 0;
      .swiper-pagination-bullet{
        width: 6px;
        height: 6px;
        background: $color-border;
        opacity: 1;
        margin: 0 2px;
        &.swiper-pagination-bullet-active{
          background: #28b670;
        }
      }
    }
  }

  @media screen and (max-width:1200px) {
    .culture-cont{
      width: auto;
      padding: px2rem(32px) px2rem(15px);
      .cont{
        &,&.cont1,&.cont2{
          float: none;
          width: auto;
        }
        &.cont1{
          padding-left: 0;
        }
        >dt{
          height: px2rem(20px);
          line-height: px2rem(20px);
          font-size: px2rem(15px);
          margin-bottom: px2rem(5px);
        }
        dd{
          font-size: px2rem(13px);
          line-height: px2rem(24px);
        }
        >dd{
          margin-bottom: px2rem(16px);
        }
        dl{
          padding-left: px2rem(15px);
          dt{
            height: px2rem(20px);
            line-height: px2rem(20px);
            font-size: px2rem(14px);
            &:before{
              left: px2rem(-15px);
              width: px2rem(4px);
              height: px2rem(4px);
              margin-top: px2rem(-2px);
            }
          }
          dd{
            margin-bottom: px2rem(6px);
          }
        }
      }
    }
  }
</style>
