<template>
  <div>
    <banner :path="bannerPath"></banner>
    <my-tabs :tabs="tabs" :index="index" @on-tab="tabchange"></my-tabs>
    <!-- <keep-alive> -->
      <component :is="currentTabComponent"></component>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import store from "@/store"
import introduction from './introduction.vue'
import structure from './structure.vue'
import culture from './culture.vue'
import { banner, myTabs } from '@/components'
import bannerPath from '@/assets/images/about/banner.jpg'
export default {
  components: {
    banner, myTabs,
    introduction,
    structure,
    culture
  },
  data () {
    return {
      tabs: [
        {name:'天游线路检测中心-欢迎光临', component: 'introduction'},
        {name:'组织架构', component: 'structure'},
        {name:'天游线路检测中心-欢迎光临', component: 'culture'}
      ],
      bannerPath
    }
  },
  computed:{
    index(){
      return store.state.aboutTab
    },
    currentTabComponent(){
      let t = this
      return t.tabs[store.state.aboutTab].component
    }
  },
  mounted () {
    
  },
  methods: {
    tabchange(e){
      window.scrollTo(0,0)
      store.mutations.setAboutTab(e)
    }
  }
}
</script>

<style lang="scss">

</style>
